<template>
  <div>

    <MenuItem data-toggle="modal" data-target="#showSortDeposit">
    Sort deposit yako
    <template #subtitle>
      Deposit not reflecting? Sort your missing deposit
    </template>
    </MenuItem>
    <div ref="jisortModal" class="modal deposit-modal fade" id="showSortDeposit" tabindex="-1"
      aria-labelledby="showSortDeposit" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">

          <div class="modal-body">

            <div class="wallet-modal">
              <div class="md-title">
                <h4 class="text-light">
                  Sort deposit yako
                </h4>
                <span style="color: var(--yellow);">
                  Deposit not reflecting? Sort your missing deposit
                </span>
              </div>
              <section class="form">
                <div v-if="message" class="alert" v-bind:class="{
                  'alert-success': message.type === 'success',
                  'alert-danger': message.type === 'error'
                }" role="alert">
                  {{ message.message }}
                </div>
                <input type="text" class="form-control std-input" name="amount"
                  placeholder="e.g CP92****** Confirmed..." min="0" aria-label="Amount" v-model="transaction_id" />
                <button @click="jisort" v-bind:class="loading" class="btn-std btn-prim btn-large" :disabled="loading">
                  {{ loading ? 'Processing...' : 'JISORT' }}
                </button>

              </section>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import walletserve from "@/services/walletserve";


import MenuItem from './MenuItem.vue';

export default {
  name: "SortDeposit",
  data: () => ({
    transaction_id: null,
    loading: false,
    message: null,
  }),
  components: {
    MenuItem
  },
  mounted() {
    window.$(this.$refs.jisortModal).on('hidden.bs.modal', this.reset)
  },

  methods: {
    reset() {
      this.transaction_id = null;
      this.message = null;
      this.loading = false;
    },

    jisort() {
      this.message = null;
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: 'Please login to proceed',
          type: 'error',
          position: 'top'
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var vm = this;
      var path = "/deposit/reconcile";

      var data = {
        transaction_id: this.transaction_id,
      };

      this.loading = true;

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          var msg = res.data.data;
          this.message = {
            message: msg,
            type: 'success'
          }

        })
        .catch((err) => {
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              this.$toast.open({
                message: 'Your session on this device has expired',
                type: 'error',
                position: 'top'
              });
              // vm.logout();
              return;
            } else {
              this.message = {
                message: err.response.data.error_message ?? 'Failed',
                type: 'error'
              }

            }
          } else if (err.request) {
            this.message = {
              message: 'Network Error. Check your network connection and try again',
              type: 'error'
            }


          } else {
            this.message = {
              message: 'An error occurred. Please try again',
              type: 'error'
            }
          }
        })
        .finally(() => {
          this.loading = false

        })
    },
  }
}
</script>

<style scoped>
.form,
.wallet-modal {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.deposit-modal {
  background: rgba(0, 0, 0, 0.5);
}

.alert {
  position: relative;
}

.wallet-modal {
  background: var(--primary);
  padding: 1rem;
  border-radius: 0.5rem;
}

.modal-dialog {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.modal-content {
  margin-top: auto;
  background: none;
}

.md-title {
  display: flex;
  flex-direction: column;
}

.md-title h4 {
  margin: 0;
}

.md-title span {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5)
}
</style>